<template>
  <EditBinNumberDialog
    value="true"
    :bin="bin"
    :error-message="errorMessage"
    :update-loading="updateLoading"
    :data-loading="!!binLoading"
    @ok="onOk"
    @cancel="onCancel"
  />
</template>

<script>
import { BIN_QUERY, BIN_UPDATE } from "./graphql";
import EditBinNumberDialog from "./components/EditBinNumberDialog.vue";

export default {
  name: "EditBinNumberDialogView",
  components: { EditBinNumberDialog },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    binId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      bin: {},
      errorMessage: undefined,
      updateLoading: false,
      binLoading: 0,
    };
  },
  computed: {
    binNumber() {
      return this.bin ? this.bin.name : undefined;
    },
  },
  apollo: {
    bin: {
      loadingKey: "binLoading",
      query: BIN_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          id: this.binId,
        };
      },
      skip() {
        return !this.binId;
      },
      update(data) {
        return data.bin;
      },
    },
  },
  methods: {
    onCancel() {
      this.$router.back();
    },
    async onOk(updateBin) {
      this.errorMessage = undefined;
      this.updateLoading = true;

      try {
        await this.handleUpdateBin(this.binId, updateBin);
      } catch (error) {
        this.errorMessage = error.toString();
        this.updateLoading = false;
        return;
      }

      this.updateLoading = false;
      this.$router.back();
    },
    async handleUpdateBin(binId, bin) {
      const result = await this.$apollo.mutate({
        mutation: BIN_UPDATE,
        variables: {
          input: {
            id: binId,
            name: bin.name.toString(),
          },
        },
      });

      const errors = result?.data?.mutationResult?.errors || [];

      if (errors.length) {
        throw Error(errors.join("; "));
      }

      return result;
    },
  },
};
</script>
