<template>
  <VxDialog
    v-model="modelValue"
    :v-size="'small'"
    :h-size="'xsmall'"
    v-bind="$props"
    persistent
    :title="dialogTitle"
    @back="cancel"
  >
    <VxForm v-if="!dataLoading" ref="form" @submit="ok">
      <v-container>
        <p
          v-html="$t('backstock.setup.bins.editBinNumberDialog.cautionText')"
        />
        <VxTextField
          v-model="form.name"
          name="name"
          :label="$t('backstock.setup.bins.editBinNumberDialog.binNumberLabel')"
          :placeholder="
            $t('backstock.setup.bins.editBinNumberDialog.binNumberPlaceholder')
          "
          rules="required"
        />

        <VxAlert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </VxAlert>
        <VxAlert
          v-if="bin.isFilled"
          type="warning"
          v-html="$t('backstock.setup.bins.editBinNumberDialog.isFilledAlert')"
        />
      </v-container>
    </VxForm>

    <template #actions>
      <VxBtn secondary @click="cancel">
        {{ $t("backstock.setup.bins.editBinNumberDialog.cancelButtonText") }}
      </VxBtn>
      <VxBtn text :loading="updateLoading" @click="submit">
        {{ $t("backstock.setup.bins.editBinNumberDialog.okButtonText") }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import {
  VxDialog,
  VxTextField,
  VxAlert,
  VxBtn,
  VxForm,
  useModelValue,
  useResponsiveness,
} from "@/core-ui";

export default {
  name: "BinNumberDialog",
  components: {
    VxTextField,
    VxAlert,
    VxDialog,
    VxBtn,
    VxForm,
  },
  mixins: [
    useModelValue(),
    useResponsiveness(),
    useFormObject({
      prop: "bin",
      default: {
        id: 0,
        name: "",
      },
    }),
  ],
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
    updateLoading: {
      type: Boolean,
      default: false,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogTitle() {
      return this.dataLoading
        ? this.$t("backstock.setup.bins.editBinNumberDialog.loadingText")
        : this.$t("backstock.setup.bins.editBinNumberDialog.title");
    },
  },
  methods: {
    getValidator() {
      return this.$refs.form;
    },
    cancel() {
      this.modelValue = false;
      this.$emit("cancel");
    },
    submit() {
      this.$refs.form.submit();
    },
    ok() {
      this.modelValue = false;
      this.$emit("ok", { ...this.form });
    },
  },
};
</script>
